.navbar {
  background: rgb(30, 30, 30);
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 1rem auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
}
.logo {
  height: 3rem;
  width: 4rem;
  font-size: 1.5rem;
  transition: 0.2s all ease-in-out;
}

.logo:hover {
  font-size: 1.7rem;
  transition: 0.2s all ease-in-out;
}
.desktopMenuListItem {
  margin: 1rem;
}

.desktopMenuListItem:hover {
  color: yellow;
  cursor: pointer;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid yellow;
}
.desktopMenuBtn {
  border: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border-radius: 2rem;
  color: #000;
}
.desktopMenuImg {
  object-fit: cover;
  font-size: 1.2rem;
}
.active {
  color: yellow;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid yellow;
}
.mobMenu {
  display: none;
  object-fit: cover;
  height: 1.8rem;
  transition: 0.2s all ease-in-out;
}

.mobMenu:hover {
  scale: 1.2;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}
.navMenu {
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background: rgb(50, 50, 50);
  border-radius: 1rem;
}
.listItem {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0.5rem 3rem;
  margin: 0.25rem;
}

@media screen and (max-width: 720px) {
  .mobMenu {
    display: flex;
  }
  .desktopMenu {
    display: none;
  }
  .desktopMenuBtn {
    display: none;
  }
}
