#works {
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}
.worksTitle {
  margin: 1rem 0;
  font-size: 3rem;
}
.MyProjects {
  width: 100%;
}
.worksDesc {
  font-weight: 300;
  font-size: 1rem;
  max-width: 45rem;
}
.worksImgs {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 65rem;
}
.worksImg {
  height: 20rem;
  object-fit: cover;
  margin: 0.5rem;
}
.workBtn {
  margin: 3rem 0;
  padding: 0.5rem 2.5rem;
  border: none;
  background: #fff;
  border-radius: 2rem;
  font-size: 2rem;

  font-weight: 500;
}

.overlay1 {
  position: relative;
}

.text-overlay1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  background-color: rgb(28, 28, 28);
  color: white;
  opacity: 0;
  transition: all 0.3 ease-in-out;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.overlay1:hover .text-overlay1 {
  opacity: 1;
  transition: all 0.3 ease-in-out;
}
/*  */
.overlay2 {
  position: relative;
}
.text-overlay2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  background-color: rgb(28, 28, 28);
  color: white;
  opacity: 0;
  transition: all 0.3 ease-in-out;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.overlay2:hover .text-overlay2 {
  opacity: 1;
  transition: all 0.3 ease-in-out;
}
/*  */
.overlay3 {
  position: relative;
}
.text-overlay3 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  background-color: rgb(28, 28, 28);
  color: white;
  opacity: 0;
  transition: all 0.3 ease-in-out;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.overlay3:hover .text-overlay3 {
  opacity: 1;
  transition: all 0.3 ease-in-out;
}

.overlay4 {
  position: relative;
}
.text-overlay4 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  background-color: rgb(28, 28, 28);
  color: white;
  opacity: 0;
  transition: all 0.3 ease-in-out;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.overlay4:hover .text-overlay4 {
  opacity: 1;
  transition: all 0.3 ease-in-out;
}

.overlay5 {
  position: relative;
}
.text-overlay5 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  background-color: rgb(28, 28, 28);
  color: white;
  opacity: 0;
  transition: all 0.3 ease-in-out;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.overlay5:hover .text-overlay5 {
  opacity: 1;
  transition: all 0.3 ease-in-out;
}

@media screen and (max-width: 585px) {
  .worksImg {
    height: 48vw;
    width: 90%;
  }
}
