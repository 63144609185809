.footer {
  width: 100vw;
  height: 4rem;
  background: rgb(35, 35, 35);
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: small;
}
