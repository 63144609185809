#contactPage {
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#contact {
  width: 95%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.contactPageTitle {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.contactDesc {
  display: block;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 300;
}
.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}
.name {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);
}
.email {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(40, 40, 40);
}
.msg {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(40, 40, 40);
}
.submitBtn {
  font-size: 0.9rem;
  font-weight: 500;
  background: #fff;
  padding: 0.75rem 3.5rem;
  border-radius: 0.5rem;
  margin: 2rem;
  border: none;
}
.links {
  display: flex;
  flex-wrap: wrap;
}
.link {
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
}
.link i {
  font-size: 3rem;
  object-fit: cover;
}
li i {
  font-size: 3rem;
  object-fit: cover;
}
.list-group-item {
  font-size: 1.3em;
}
.second-ul {
  display: flex;
  list-style: none;
  gap: 2rem;
  justify-content: center;
  margin-top: 1rem;
}
.second-ul li {
  transition: 0.2s all ease-in-out;
}
.second-ul li:hover {
  scale: 1.2;
  transition: 0.2s all ease-in-out;
}
