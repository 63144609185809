#intro {
  letter-spacing: 0.1rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  margin-top: 1rem;
}

.bg {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  right: 0;
  object-fit: cover;
  top: 0rem;
  height: 100vh;
}

.introText {
  text-shadow: 2px 3px #000;
  margin-bottom: 2rem;
}
.introContent {
  width: 100vw;
  padding: 2rem;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
}
.hello {
  text-shadow: 1px 1px #000;
  padding-top: 5rem;
  font-size: 1.7rem;
  font-weight: 100;
}
.introName {
  text-shadow: 1px 1px #000;

  color: yellow;
  text-shadow: 1px 1px #000;
}
.introPara {
  font-size: 1.3rem;
  font-weight: 100;
  letter-spacing: 1px;
  text-shadow: 1px 1px #000;
  width: 90%;
  margin-bottom: 4rem;
}
.btn {
  background: #fff;
  margin: 1rem 0;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 2rem;
  font-weight: 500;
  transition: 0.2s all ease-in;
}
.btn:hover {
  scale: 1.1;
  transition: 0.2s all ease-in;
  cursor: pointer;
}
@media screen and (max-width: 840px) {
  .bg {
    right: -10vw;
  }

  .introContent {
    font-size: 10vw;
    margin-top: 7rem;
  }
  .hello {
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 480px) {
  .bg {
    right: -20vw;
  }
}
